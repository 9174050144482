import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "modules/Project/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    sampleProject: sanityProject(id: { eq: $id }) {
      title
      subtitle
      thumbnail {
        image {
          alt
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, formats: JPG)
              }
            }
          }
        }
        video
        mobileVideo
      }
      slug {
        current
      }
      category {
        label
        value
      }
      mainVideo
      tags {
        label
        value
      }
      strategy {
        copy
      }
      imageCaption {
        alt
        caption
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, formats: JPG)
            }
          }
        }
      }
      brief {
        copy
      }
      imagePopout {
        title
        copy
        image {
          alt
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, formats: JPG)
              }
            }
          }
        }
      }
      gallery {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, formats: JPG)
            }
          }
        }
        alt
      }
      outcome {
        copy
        subtitle
      }
      credits {
        name
        role
      }
      relatedProject {
        accentColor
        title
        subtitle
        slug {
          current
        }
        tags {
          label
          value
        }
        category {
          label
          value
        }
        thumbnail {
          image {
            alt
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100, formats: JPG)
                }
              }
            }
          }
          video
          mobileVideo
        }
      }
      id
      accentColor
      mainVideo
    }
  }
`;

const ProjectTemplate = props => {
  const { data, errors } = props;
  const [ Smoother, setSmoother ] = React.useState(false);
  const project = data && data.sampleProject;
  return (
    <Layout preventCursor setSmoother={setSmoother}>
      <SEO
        title={project.title}
        description={project.brief ? project.brief.copy : ""}
        />
      {errors && <SEO title="GraphQL Error" />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project smoother={Smoother} {...project} />}
    </Layout>
  );
};

export default ProjectTemplate;
